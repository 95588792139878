import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import EnterPage from "./Components/Pages/enter";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import EA from "./images/EasyAgility.png";

import { Grid } from "@mui/material";
import LandingPage from "./Components/Pages/landing";

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const App = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <BrowserRouter>
      <Box
        height={"100vh"}
        maxWidth={"100vw"}
        fontFamily={"Verdana"}
        textAlign="center"
        fontWeight={"normal"}
      >
        <Box
          top={0}
          color="white"
          textAlign={"left"}
          minHeight={"10vh"}
          bgcolor="#8f9c5d"
          position="fixed"
          minWidth={"100vw"}
          zIndex={1000}
        >
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  window.location.href = "/";
                }}
                marginLeft={"2vw"}
                marginTop={1}
                display="inline-flex"
              >
                <img src={EA} alt="Easy Agility" style={{ height: "8vh" }} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          minHeight="100vh"
          bgcolor="#cfd8e3"
          style={{ opacity: 1 }}
          left={0}
          right={0}
        >
          <Routes>
            <Route path="/shows/:showId" element={<EnterPage />} />
            <Route path="/" element={<EnterPage />} />
          </Routes>
          <Typography
            bgcolor={"white"}
            position="fixed"
            bottom={0}
            fontSize={12}
          >
            © Easy Agility 2025 - All Rights Reserved
          </Typography>
        </Box>
      </Box>
    </BrowserRouter>
  );
};

export default App;
